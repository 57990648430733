import { useStaticQuery } from "gatsby";
import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import { PortfolioPageQuery } from "../../graphql-types";
import Footer from "../components/Footer";
import MenuBar from "../components/MenuBar";
import PageContent from "../components/PageContent";
import { mustBeDefined } from "../utils";

const AllInvestments: React.FC = () => {
  const queryResults = useStaticQuery<PortfolioPageQuery>(query);
  const investments = mustBeDefined(queryResults.investments).otherwise(
    "CMS_DATA_MISSING",
    "PortfolioPageQuery.investments"
  )

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="BAM Elevate All Investments Page" />
        <title>All Investments</title>
      </Helmet>
      <main className="w-screen bg-off-white">
        <MenuBar />
        <PageContent className="pt-16">
          <ul className="list-disc pl-5">
            {
              investments.edges.map(edge => {
                return (
                  <li className="text-sm">
                    {edge.node.name}
                  </li>
                )
              })
            }
          </ul>
        </PageContent>
        <PageContent>
          <Footer />
        </PageContent>
      </main>
    </>
  )
}

export default AllInvestments;


export const query = graphql`
  query AllInvestmentsPage {
    investments: allStrapiInvestment(sort: {order: ASC, fields: name}) {
      edges {
        node {
          name
        }
      }
    }
  }
`;